import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f83c22f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "fw-bolder text-muted" }
const _hoisted_9 = { class: "min-w-120px" }
const _hoisted_10 = { class: "min-w-120px" }
const _hoisted_11 = { class: "min-w-120px" }
const _hoisted_12 = { class: "min-w-120px" }
const _hoisted_13 = { class: "min-w-120px" }
const _hoisted_14 = { class: "min-w-120px" }
const _hoisted_15 = { class: "min-w-100px text-end" }
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "pt-3 max-width-220" }
const _hoisted_20 = { class: "text-dark fw-bolder d-block fs-6" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "text-end" }
const _hoisted_23 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTNewTargetModal = _resolveComponent("KTNewTargetModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.widgetClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("todaySubscriptions")), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("homeUsersDescription")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_8, [
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t("subscriber")), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.t("nameCourse")), 1),
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t("email")), 1),
                _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.t("phoneNumber")), 1),
                _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.t("group")), 1),
                _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.t("summa")), 1),
                _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.t("actions")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Subscribers, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.goDetails(item.id)),
                          class: "text-dark fw-bolder text-hover-primary fs-6"
                        }, _toDisplayString(item.name) + " " + _toDisplayString(item.lname), 9, _hoisted_18),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(item.notes), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(item.course_name), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: _ctx.mailto(item.email),
                      class: "text-dark fw-bolder text-hover-primary d-block fs-6"
                    }, _toDisplayString(item.email), 9, _hoisted_21)
                  ]),
                  _createElementVNode("td", null, _toDisplayString(item.phone), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(item.group), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(item.payment_cost), 1)
                  ]),
                  _createElementVNode("td", _hoisted_22, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.goDetails(item.id)),
                      class: "btn btn-secondary btn-sm m-1"
                    }, _toDisplayString(_ctx.t("view")), 9, _hoisted_23)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ], 2),
    _createVNode(_component_KTNewTargetModal)
  ], 64))
}