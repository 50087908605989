
import { computed, defineComponent, onMounted, ref } from "vue";
import KTNewTargetModal from "@/components/modals/forms/NewTargetModal.vue";
import { useRoute, useRouter } from "vue-router";
import { Actions} from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { replaceMonth } from "@/store/enums/months";
import { useI18n } from "vue-i18n";
import moment from "moment";

export default defineComponent({
  name: "kt-widget-9-arrears",
  components: {
    KTNewTargetModal,
  },
  props: {
    widgetClasses: String,
    hideBtnNewMember: Boolean,
  },
  setup() {
    const store = useStore()
    const checked = ref(false);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const now = moment(new Date());

    const dataTable = computed(() => {
      const table = store.getters.subscribersDebtors;
      if (table.length === 0) {
          return [];
      }
      const tableData = table.map((item) => {
        let duration = moment.duration(moment(item.date_expired_debtor).diff(now));
        return {
          expired: "",
          status: item?.status ? item.status : "",
          date_expired_debtor: item?.date_expired_debtor ? duration.asDays().toFixed(0) : "",
          name: item?.user?.fname ? item.user.fname : "",
          lname: item?.user?.lname ? item.user.lname : "",
          notes: item?.user?.notes ? item?.user?.notes : "",
          course_name: item?.course_id?.course_name,
          phone: item?.user?.phone ? item.user.phone : "",
          group: item?.group_name ? item.group_name : "",
          email: item?.user?.email ? item.user.email : "",
          payment_cost: item?.payment_cost ? item?.payment_cost : "",
          id: item?._id ? item._id : "",
        };
      });
  
      return tableData ? tableData : [];
    });

    const goDetails = (id) => {
      store.dispatch(Actions.GET_SUBSCRIBERS_ONE, {'id': id})
          .then(() => {
            router.push({ name: 'subscription', params: { id: id } })
          })
    };

    return {
      dataTable,
      checked,
      goDetails,
      t,
    };
  },
});
