
import { defineComponent, ref, computed } from "vue";
import KTNewTargetModal from "@/components/modals/forms/NewTargetModal.vue";
import {useRoute, useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTNewTargetModal
  },
  props: {
    widgetClasses: String,
    hideBtnNewMember: Boolean
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const checked = ref(false);
    const list = [
      {
        image: "media/avatars/150-11.jpg",
        name: "Ana Simmons",
        skills: "HTML, JS, ReactJS",
        phone: '+7(912)-033-32-55',
        companyName: "Intertico",
        paymentAmount: "#15756",
        group: 'lorem ipsum',
        companySkills: "Web, UI/UX Design",
        value: "50",
        email: 'alexrequire@mail.ru',
        color: "primary",
      },
    ];

    const Subscribers = computed(() => {
      const data = store.getters.subscribersToday;
      if (data.length === 0) {
        return [];
      }
      const table = data.map((item, inx) => {
          return {
            id: item._id,
            count: inx + 1,
            name: item?.user?.fname,
            notes: item?.user?.notes,
            lname: item?.user?.lname,
            course_name: item?.course_id?.course_name,
            email: item?.user?.email,
            phone: item?.user?.phone,
            group: item?.group_name,
            expired: item?.date_expired,
            payment_cost: item?.payment_cost,
            payment: {
              ccnumber: item?.card_last4,
              firstnumber: item?.card_first6
            }
          }
      })
      return  table ? table : [];
    });  

    const goDetails = (id) => {
      store.dispatch(Actions.GET_SUBSCRIBERS_ONE, {'id': id})
          .then(() => {
            router.push({ name: 'subscription', params: { id: id } })
          })
    };

    const mailto = (email) => {
      return "mailto:" + email;
    };

    return {
      list,
      checked,
      Subscribers,
      goDetails,
      mailto,
      t,
    };
  },
});
