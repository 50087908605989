
import { defineComponent, onMounted } from "vue";
import TableWidget9 from "@/components/widgets/tables/HomeUsers.vue";
import TableArrears from "@/components/widgets/tables/TableWidget9Arrears.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "dashboard",
  components: {
    TableWidget9,
    TableArrears,
  },
  created() {
    store.dispatch(Actions.GET_SUBSCRIBERS_TODAY)
    .then(() => store.dispatch(Actions.GET_SUBSCRIBERS_DEBTORS))

  },
  beforeRouteUpdate: (to, from, next) => {
    store.dispatch(Actions.GET_SUBSCRIBERS_TODAY)
        .then(() => store.dispatch(Actions.GET_SUBSCRIBERS_DEBTORS)
            .then(() => next()))
  },

  setup() {
    const { t } = useI18n();

    onMounted(() => {
      setCurrentPageTitle( t("home") );
    });
  },
});
